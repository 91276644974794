import React from "react";
import "./DeepDive.scss";
function DeepDive() {
  const featureData = [
    {
      number: "01",
      title: "Non-Custodial Control",
      description:
        "Take charge of your digital assets by maintaining complete control over your private keys.",
    },
    {
      number: "02",
      title: "Comprehensive Services",
      description:
        "Get access to a range of financial activities, alongside USDT-based digital and physical Mastercards.",
    },
    {
      number: "03",
      title: "Advanced Security",
      description:
        "Cutting-edge encryption techniques to safeguard user data and transactions, ensuring top-notch privacy and confidentiality.",
    },
    {
      number: "04",
      title: "Seamless Integration",
      description:
        "Complete financial management solution and connectivity with diverse decentralized applications and platforms.",
    },
    {
      number: "05",
      title: "Cross-Chain Functionality",
      description:
        "Asset exchanges across different blockchains, enhancing user flexibility, interoperability, and eliminating intermediaries.",
    },
    {
      number: "06",
      title: "Regulatory Compliance",
      description:
        "Adherence to regulations and securities laws, through security token (TST), that represents real-world assets.",
    },
    {
      number: "07",
      title: "Community Incentives",
      description:
        "A generous share of tokens dedicated to community rewards, staking benefits, and referral incentives.",
    },
    {
      number: "08",
      title: "User-Friendly Interface",
      description:
        "Crafted for effortless navigation, appealing to users of all levels from beginners to seasoned experts.",
    },
  ];

  return (
    <div className="deepdive"id="deepdive">
        <video className="deepdive_video" src={'https://d2j1mnvck8397w.cloudfront.net/deepdive.mp4'} muted autoPlay loop playsInline controls={false} />
      <div className="container deepdiveinner">
        <h2>Deep Dive into Super Features</h2>
        <div className="deepdiveinner_feature">
          <div className="deepdiveinner_feature_listing">
            {featureData.slice(0, 4).map((feature, index) => (
              <div className="listingItems" key={index}>
                <span>{feature.number}.</span>
                <div>
                  <h4>{feature.title}</h4>
                  <p>{feature.description}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="deepdiveinner_feature_listing">
            {featureData.slice(4).map((feature, index) => (
              <div className="listingItems" key={index + 4}>
                <span>{feature.number}.</span>
                <div>
                  <h4>{feature.title}</h4>
                  <p>{feature.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeepDive;

import React, { useEffect } from "react";
import InputCustom from "../../Common/InputCustom/InputCustom.jsx";
import "./ContactUs.scss";
import { Row, Col } from "antd";
import { ButtonCustom } from "../../Common/ButtonCustom/ButtonCustom.jsx";
import CustomSelect from "../../Common/CustomSelect/CustomSelect.jsx";

const options = [
  { value: "jack", label: "Jack" },
  { value: "lucy", label: "Lucy" },
  { value: "tom", label: "Tom" },
];

function ContactUs() {
  useEffect(() => {
    // Add the class to the body when the component mounts
    document.body.classList.add("contactUsBg");

    // Remove the class from the body when the component unmounts
    return () => {
      document.body.classList.remove("contactUsBg");
    };
  }, []);

  const handleChange = (value) => {
    console.log(`Selected: ${value}`);
  };

  const handleSearch = (value) => {
    console.log("Search:", value);
  };

  return (
    <div className="contactUsMain">
      <div className="contactUs">
        <h2>Contact Us</h2>
        <p>
          If you have any questions, please don’t hesitate to contact us. Our
          team is here to assist you and offer the support you require. Fill in
          the details in the form below, and our experts will get back to you
          shortly.
        </p>
        <form className="contactUs_form">
          <Row gutter={[18, 23]}>
            <Col xs={24} md={8}>
              <InputCustom
                regularInput
                label="First Name"
                placeholder="Enter name"
                required
              />
            </Col>
            <Col xs={24} md={8}>
              <InputCustom
                regularInput
                label="Last Name"
                placeholder="Enter name"
                required
              />
            </Col>
            <Col xs={24} md={8}>
              <InputCustom
                regularInput
                label="Email Address"
                placeholder="Enter email"
                required
              />
            </Col>
            <Col xs={24} md={8}>
              <InputCustom
                regularInput
                label="Phone Number"
                placeholder="Enter number"
                required
              />
            </Col>
            <Col xs={24} md={8}>
              <CustomSelect
                label="Purpose"
                options={options}
                required
                onChange={handleChange}
                onSearch={handleSearch}
                placeholder="Select"
              />
            </Col>
            <Col xs={24}>
              <InputCustom
                label="Description"
                placeholder="Enter here"
                textareaInput
                required
              />
            </Col>
          </Row>
          <div className="contactUs_form_btn">
            <ButtonCustom
              greenbutton
              label="Submit"
              htmlType="submit"
              btnBorder={true}
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export default ContactUs;

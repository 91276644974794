import { useState } from "react";
import "./Input.scss";

function InputCustom(props) {
  const {
    label,
    placeholder,
    type,
    error,
    regularInput,
    passwordInput,
    textareaInput,
    id,
    name,
    onChange,
    value,
    customClass,
    tabIndex,
    onBlur,
    disabled,
    required,
  } = props;

  return (
    <div
      className={`customInput ${
        passwordInput ? "customInput-password" : ""
      } ${customClass} ${error ? "customInput-inputError" : ""}`}
    >
      <label>
        {required && <sup> *</sup>}
        {label}
      </label>
      {regularInput && (
        <input
          placeholder={placeholder}
          type={type}
          id={id}
          name={name}
          onChange={onChange}
          value={value}
          tabIndex={tabIndex}
          onBlur={onBlur}
          disabled={disabled}
        />
      )}
      {textareaInput && (
        <textarea
          placeholder={placeholder}
          id={id}
          name={name}
          onChange={onChange}
          value={value}
          tabIndex={tabIndex}
          onBlur={onBlur}
          disabled={disabled}
        />
      )}
      {error && <p>{error}</p>}
    </div>
  );
}

export default InputCustom;

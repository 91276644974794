import React from "react";
import { Select } from "antd";
import "./CustomSelect.scss";

const { Option } = Select;

function CustomSelect(props) {
  const { label, options, onChange, onSearch, placeholder,customClass,required,error } = props;

  return (
    <div
      className={`customSelect ${customClass} ${
        error ? "customSelect-error" : ""
      }`}
    >
      <label>
        {required && <sup>*</sup>}
        {label}
      </label>
      <Select
        showSearch
        placeholder={placeholder}
        optionFilterProp="label"
        onChange={onChange}
        onSearch={onSearch}
        className="customSelect-dropdown"
      >
        {options.map((option) => (
          <Select.Option
            key={option.value}
            value={option.value}
            label={option.label}
          >
            {option.label}
          </Select.Option>
        ))}
      </Select>
      {error && <p>{error}</p>}
    </div>
  );
}

export default CustomSelect;

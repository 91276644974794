import React, { useState, useEffect, useCallback } from "react";
import { Row, Col } from "antd";
import { Link, useLocation,useNavigate } from "react-router-dom";
import logo from "../../assets/logo.svg";
import "./Footer.scss";
import { Input } from "antd";
import {
  Discord,
  Dotsocial,
  GreenArrow,
  Insta,
  Radix,
  Telegram,
  LinkedIn,
  Twitter,
} from "../SvgIcons/SvgIcons";
function Footer() {
  const [scrollTarget, setScrollTarget] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const handleScroll = useCallback((event, targetId) => {
    event.preventDefault();
    if (location.pathname === "/") {
      const targetElement = document.getElementById(targetId);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      setScrollTarget(targetId);
      navigate("/");
    }
  }, [location.pathname, navigate]);
  return (
    <div className="footernew">
      <div className="container">
        <Row gutter={[16, 16]} justify="center">
          <Col xs={24} sm={24} md={12} lg={12}>
            <span className="footerlogo"> 
              <img src={logo} alt="logo" />
            </span>
            <p>
              Designed to serve globetrotters, savvy investors, remote workers,
              and the unbanked populace alike, Triskel unlocks your way to
              financial freedom.
            </p>
            <div className="footerInput">
              <Input
                placeholder="Enter Email Address"
                suffix={
                  <span className="greenArrow">
                    <GreenArrow />
                  </span>
                }
              />
            </div>
            <p className="footerYear">2024 Triskel / ALL RIGHTS RESERVED</p>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8}>
            <div className="footerLegal">
              <div>
                <h3>LEGAL</h3>
                <Link to="" target="">
                  Privacy Policy
                </Link>
                <Link to="" target="">
                  Terms of Use
                </Link>
                <Link to="" target="">
                  Risk Disclaimer
                </Link>
                <Link to="" target="">
                  Terms and Conditions
                </Link>
              </div>

              <div>
                <h3>COMPANY</h3>
                <Link to="/" target="_blank"onClick={(e) => handleScroll(e, 'banner')}>
                  Home
                </Link>
                <Link to="/" target="_blank"onClick={(e) => handleScroll(e, 'whyTriskel')}>
                  Why Triskel
                </Link>
                <Link to="/features" target="_blank"onClick={(e) => handleScroll(e, 'feature')}>
                  Features
                </Link>
                <Link to="/useCases" target="_blank"onClick={(e) => handleScroll(e, 'usecases')}>
                  Use Cases
                </Link>
                <Link to="/triskelToken" target="_blank"onClick={(e) => handleScroll(e, 'token')}>
                  Triskel Token
                </Link>{" "}
                <Link to="/roadmap" target="_blank"onClick={(e) => handleScroll(e, 'roadmap')}>
                  Roadmap
                </Link>
                <Link to="/whitepaper" target="_blank"onClick={(e) => handleScroll(e, 'deepdive')}>
                  Whitepaper
                </Link>
              </div>
            </div>
          </Col>

          <Col xs={24} lg={4}>
            <h3 className="followus">FOLLOW US</h3>
            <div className="socialIcons" style={{paddingTop:"10px"}}>
              <Link to="" target="_blank">
               <Radix/>
              </Link>
              <Link to="" target="_blank">
                <Twitter />
              </Link>
              <Link to="" target="_blank">
                <Telegram />
              </Link>
              <Link to="" target="_blank">
                <Discord />
              </Link>
            </div>
            <div className="socialIcons">
              <Link to="" target="_blank">
               <Insta/>
              </Link>
              <Link to="" target="_blank">
               <LinkedIn/>
              </Link>
              <Link to="" target="_blank">
               <Dotsocial/>
              </Link>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Footer;


import "./App.scss";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import routes from "./Routing/Routes";
import "./index.scss";

function App() {
  // Main routing
  const router = createBrowserRouter([
    {
      path: "/",
      children: routes,
      errorElement: <div style={{ color: "#fff" }}>Error</div>,
    },
  ]);
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;

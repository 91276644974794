import React, { useState, useEffect, useCallback } from "react";
import { NavLink, useLocation,useNavigate } from "react-router-dom";
import CustomDropdown from "../CustomDropdown/CustomDropdown.jsx";
import { ButtonCustom } from "../ButtonCustom/ButtonCustom.jsx";
function HeaderRight() {
  const [scrollTarget, setScrollTarget] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const handleScroll = useCallback((event, targetId) => {
    event.preventDefault();
    if (location.pathname === "/") {
      const targetElement = document.getElementById(targetId);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      setScrollTarget(targetId);
      navigate("/");
    }
  }, [location.pathname, navigate]);
  const handleClick = useCallback(() => {
    setScrollTarget('contactUsSection'); 
    navigate("/contactUs", { replace: true });
    window.scrollTo(0, 0); // Scroll to the top of the screen
  }, [navigate]);
  useEffect(() => {
    if (scrollTarget && location.pathname === "/") {
      const targetElement = document.getElementById(scrollTarget);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
      }
      setScrollTarget(null);
    }
  }, [scrollTarget, location.pathname]);

  return (
    <>
      <NavLink to="/" onClick={(e) => handleScroll(e, 'banner')}>Home</NavLink>
      <NavLink to="/" onClick={(e) => handleScroll(e, 'whyTriskel')}>Why Triskel</NavLink>
      <NavLink to="/" onClick={(e) => handleScroll(e, 'feature')}>Features</NavLink>
      <NavLink to="/" onClick={(e) => handleScroll(e, 'usecases')}>Use Cases</NavLink>
      <NavLink to="/" onClick={(e) => handleScroll(e, 'token')}>Triskel Token</NavLink>
      <NavLink to="/" onClick={(e) => handleScroll(e, 'roadmap')}>Roadmap</NavLink>
      <NavLink to="https://www.en.triskel-group.com/Whitepaper-Triskel-2024-EN.pdf" target="_blank">WhitePaper</NavLink>
      <div className="navButtons">
        <CustomDropdown />
        <ButtonCustom
          greenborder
          label="Contact Us"
          onClick={handleClick}
          btnBorder={true}
        />
      </div>
    </>
  );
}

export default HeaderRight;

import React from "react";
import { Dropdown, Menu } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const CustomDropdown = () => {
  // Dummy data for dropdown menu items
  const menuItems = [
    {
      key: "1",
      label: (
        <Link
          to="https://play.google.com/store/apps/details?id=com.triskel&hl=hi"
          target={"_blank"}
        >
          Play Store
        </Link>
      ),
    },
    {
      key: "2",
      label: (
        <Link
          to="https://apps.apple.com/in/app/triskel-wallet/id6449494626"
          target={"_blank"}
        >
          App Store
        </Link>
      ),
    },
  ];

  // Handle menu item click
  const handleMenuClick = (e) => {
    console.log("Clicked on menu item:", e.key);
    // Perform actions based on the clicked menu item
  };
  const menu = (
    <Menu onClick={handleMenuClick}>
      {menuItems.map((item) => (
        <Menu.Item key={item.key}>{item.label}</Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown overlay={menu}>
      <span style={{ cursor: "pointer" }}>
        Download <DownOutlined />
      </span>
    </Dropdown>
  );
};

export default CustomDropdown;

import React from "react";
import blackapp from "../../../../assets/blackapp.svg"
import downloadphone from "../../../../assets/downloadphone.png"
import blackpay from "../../../../assets/blackgpay.svg"
import { Link } from "react-router-dom";
import "./Download.scss"
function DownloadSuperApp() {
  return (
    <div className="downsuperapp container">
      <div className="downsuperapp_left">
        <h2>Download Triskel Superapp to Access All Web3 in One App</h2>
        <div className="downsuperapp_left_buttons">
            <Link to =""><img src={blackapp}alt="imgs"/></Link>
            <Link to =""><img src={blackpay}alt="imgs"/></Link>
        </div>
      </div>
      <div className="downsuperapp_right">
        <img src={downloadphone}alt="imgs"/>
      </div>

    </div>
  );
}

export default DownloadSuperApp;

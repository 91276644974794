import React from "react";
import { Link } from "react-router-dom";
import { GreenApp, GreenPlay } from "../../../../Common/SvgIcons/SvgIcons";
import "./Banner.scss";
function Banner() {
  return (
    <div className="banner" id="banner">
      <video src={'https://d2j1mnvck8397w.cloudfront.net/mainbanner.mp4'} muted autoPlay loop playsInline controls={false} />
      <div className="banner_data">
        <h3>Web3 Superapp<br/> for Next-gen Investors</h3>
        <p>All-in-One Non-Custodial wallet for DeFi Services.</p>
        <div className="bannerbuttons">
          <Link to="">
            <GreenPlay />
          </Link>
          <Link to="">
            <GreenApp />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Banner;

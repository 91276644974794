import React from "react";
import "./Explore.scss";
import Lottie from "lottie-react";
import animationData from "../../../../assets/StoreAsset/Inclusivity.json";
import jsondata from "../../../../assets/StoreAsset/Decentralization.json";
import xsysondata from "../../../../assets/StoreAsset/Innovation.json";

function Explore() {
  const items1 = [
    {
      animationData: animationData,
      title: "Inclusivity",
      text: "Providing equal access and diverse opportunities to all, paving the path for a globalized financial ecosystem.",
    },
    {
      animationData: jsondata,
      title: "Decentralization",
      text: "Extending complete financial autonomy in the hands of users, by removing the need for intermediaries and centralized authorities.",
    },
    {
      animationData: xsysondata,
      title: "Innovation",
      text: "Integrating the latest advancements and cutting-edge blockchain solutions, thus building a stronger and better financial landscape.",
    },
  ];

  return (
    <div className="explore">
      <h2>
        Explore the Power of
        <br />
        <span> Web3 Finance</span>{" "}
      </h2>
      <p className="explore_text">
        Triskel is an all-in-one non-custodial cryptocurrency wallet that
        embodies the ideals of financial inclusion, decentralization, and
        technological innovation
      </p>
      <div className=" explore_inner container">
        <div className="explore_inner_data">
          {items1.map((item, index) => (
            <div className="innerItems" key={index}>
              <span className="innerItems_lottieData">
                <Lottie animationData={item.animationData} className="lottiePlayer"/>
              </span>
              <h4>{item.title}</h4>
              <p>{item.text}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Explore;

import React from "react";
import "./OurCertificate.scss";

function OurCertificate() {
  const certificates = [
    {
      id: 1,
      state: "State of Wyoming",
      united: "(United States)",
      view: "View",
    },
    {
      id: 2,
      state: "Saint Vincent and the Grenadines",
      united: "(United States)",
      view: "View",
    },
    {
      id: 3,
      state: "Republic of the Marshall Islands",
      united: "(United States)",
      view: "View",
    },
    {
      id: 4,
      state: "Triskel Technologies International S.A.",
      united: "(United States)",
      view: "View",
    },
    {
        id: 5,
        state: "Dubai",
        united: "(United States)",
        view: "View",
      },
  ];

  return (
    <div className="container certificates">
      <h2>Our Certificates</h2>
      <div className="certificates_card">
        {certificates.map((certificate) => (
           <div className="certificates_card_inner" key={certificate.id}>
            <p>{certificate.state}</p>
            <span>{certificate.united}</span>
            <span className="greenright">{certificate.view}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default OurCertificate;

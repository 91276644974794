import React from "react";
import { StoreImages } from "../../../../assets/StoreAsset/StoreImages";
import "./UseCases.scss";
function UseCases() {
  const { shopping, travel, crypto, instantpay, crossborder, donation } =
    StoreImages;
  const useCasesData = [
    {
      imgSrc: shopping,
      title: "Shopping",
      description:
        "Enjoy USDT-linked MasterCards for hassle-free online shopping and international spending!",
    },
    {
      imgSrc: travel,
      title: "Travel",
      description:
        "With our globally accepted MasterCard, make travel payments in online and offline modes, with minimal transaction fees.",
    },
    {
      imgSrc: crypto,
      title: "Crypto to Fiat",
      description:
        "Transform your cryptocurrency into cash in a breeze, skipping the need to transfer to bank accounts.",
    },
    {
      imgSrc: donation,
      title: "Donations",
      description:
        "Fund charitable organizations in the form of cryptocurrencies globally directly from your wallet.",
    },
    {
      imgSrc: crossborder,
      title: "Cross-border Payments",
      description:
        "Swift and fee-free cross-border transactions made perfect for travelers and expatriates.",
    },
    {
      imgSrc: instantpay,
      title: "Instant Payments",
      description:
        "Receive payments from clients globally without the delays and fees associated with traditional banking.",
    },
  ];
  return (
    <div className="useCases container "id="usecases">
      <h2>Use Cases of Triskel MasterCards</h2>
      <p>
        Our digital and physical MasterCards can be utilized for a variety of
        purposes.
      </p>
      <div className="useCases_inner">
        {useCasesData.map((useCase, index) => (
          <div className="useCases_inner_cards" key={index}>
            <img src={useCase.imgSrc} alt="imgs" />
            <h4>{useCase.title}</h4>
            <p>{useCase.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default UseCases;
